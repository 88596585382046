import { onCLS } from 'web-vitals/attribution';

function logCLS({name, delta, value, id, attribution}) {
  var eventParams = {
    name: name,
    value: delta,
    id: id,
    value: value,
    delta: delta,
    debug_target: attribution.largestShiftTarget,
    debug_target_shift: {
      before_rect: attribution.largestShiftSource.previousRect.toJSON(),
      after_rect: attribution.largestShiftSource.currentRect.toJSON()
    }
  }

  dataLayer.push({
    'event': 'coreWebVitals',
    'webVitalsMeasurement': {
      'CLS': eventParams
    }
  });
}

onCLS(logCLS);
